/* 隐藏整个页面的滚动条 */
html {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

/* Chrome, Safari 和 Opera */
html::-webkit-scrollbar {
    display: none;
}

/* 隐藏特定元素的滚动条 */
.no-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* 保留滚动功能但美化滚动条样式 */
.custom-scrollbar {
    /* 设置滚动条宽度 */
    scrollbar-width: thin; /* Firefox */

    /* Chrome/Safari/Opera */
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

@font-face {
    font-family: 'Nautilus-pompilius';
    src: url('/fonts/Nautilus-pompilius-2.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
}



#site-name {
    font-family: 'Nautilus-pompilius', PingFang SC, 'Hiragino Sans GB', 'Microsoft JhengHei', 'Microsoft YaHei', sans-serif !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#site-title {
    font-family: 'Nautilus-pompilius', PingFang SC, 'Hiragino Sans GB', 'Microsoft JhengHei', 'Microsoft YaHei', sans-serif !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}